import React, { useState, useEffect }from 'react';
import AppRoutes from './approutes.js';
import './App.css';
import { UserContext } from './services/usercontext';

function App() {
  
  const [User, setUser] = useState({});

  useEffect(() => {
    //console.log(User);
    if (User) {
    }
  }, [User]);

  return (
    <div className="App">
      <UserContext.Provider value={ { User, setUser } }>
        <AppRoutes />
      </UserContext.Provider>
    </div>
  );
}

export default App;
