import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../services/usercontext";
import csuapi from "../services/csuapi";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

export default function LastVersion() {
  const { User } = useContext(UserContext);
  const [gridItems, setGridItems] = useState([]);
  const [typeProgramID, setTypeProgramID] = useState(0);
  const [tiposPrograma, setTiposPrograma] = useState([
    { CodTipoProg: 0, NomeTipoProg: "Todos" },
  ]);
  const link = "/prod";
  const linkTiposProgramas = "/prod/tiposprograma";
  const history = useNavigate();
  const config = {
    headers: {
      token: User.token,
      tipo: typeProgramID,
    },
  };
  const columns = [
    {
      field: "CodProg",
      headerName: "Código",
      width: 70,
      editable: false,
      description: "Código do programa.",
      sortable: false,
    },
    {
      field: "DescProg",
      headerName: "Descrição",
      width: 350,
      editable: false,
      description: "Descrição do programa.",
    },
    {
      field: "VerProg",
      headerName: "Versão",
      // type: 'number',
      width: 150,
      editable: false,
      description: "Versão disponibilizada.",
    },
    {
      field: "Tipo",
      headerName: "Tipo",
      // type: 'number',
      width: 150,
      editable: false,
      description: "Tipo.",
    },
    {
      field: "LinkVersion",
      headerName: "Download",
      description: "Link para download do programa.",
      sortable: false,
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Link to={params.value} target="_blank" rel="noopener noreferrer">
          <Tooltip title={params.value} arrow placement="right">
            <DownloadIcon />
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "LinkDoc",
      headerName: "Release Notes",
      description: "Link para download do release notes.",
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (params) =>
        params.value !== "" && (
          <Link to={params.value} target="_blank" rel="noopener noreferrer">
            <Tooltip title={params.value} arrow placement="right">
              <DownloadIcon />
            </Tooltip>
          </Link>
        ),
    },
    {
      field: "LinkManual",
      headerName: "Manual",
      description: "Link para download do manual.",
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (params) =>
        params.value !== "" && (
          <Link to={params.value} target="_blank" rel="noopener noreferrer">
            <Tooltip title={params.value} arrow placement="right">
              <DownloadIcon />
            </Tooltip>
          </Link>
        ),
    },
    {
      field: "DataHora",
      headerName: "Data/Hora",
      // type: 'number',
      width: 300,
      editable: false,
      description: "Data/Hora homologação.",
    },
  ];

  const handleTypeProgramChange = (event) => {
    setTypeProgramID(event.target.value);
  };

  function getItemsGrid(aTypeProgramID) {
    config.headers["tipo"] = aTypeProgramID;

    csuapi
      .get(link, config)
      .then(function (response) {
        console.log(response.data);
        if (response.data == null) {
          setGridItems({});
        } else {
          setGridItems(response.data);
        }
      })
      .catch(function (error) {
        setGridItems({});
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  function getTiposPrograma(aTypeProgramID) {
    config.headers["tipo"] = aTypeProgramID;

    csuapi
      .get(linkTiposProgramas, config)
      .then(function (response) {
        setTiposPrograma(response.data);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  useEffect(() => {
    getItemsGrid(typeProgramID);
  }, [typeProgramID]);

  useEffect(() => {
    getTiposPrograma();
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Stack spacing={5} sx={{ width: 300 }}>
        <TextField
          select
          variant={"outlined"}
          label="Filtrar Tipo Programa"
          sx={{ width: 250 }}
          value={typeProgramID}
          onChange={handleTypeProgramChange}
        >
          {tiposPrograma.map((item) => (
            <MenuItem key={item.CodTipoProg} value={item.CodTipoProg}>
              {item.NomeTipoProg}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <DataGrid
        rowHeight={38}
        getRowId={(gridItems) => gridItems.VersionID}
        rows={gridItems}
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: "CodProg", sort: "asc" }],
            // sortModel: [{ field: 'DescProg', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        // checkboxSelection
      />
    </Box>
  );
}
