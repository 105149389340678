import React, { Suspense, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';


import Main from './pages/main';
import Login from './pages/login';
import { UserContext } from './services/usercontext';


export default function AppRoutes({ history }) {

    const { User } = useContext(UserContext);

    const ProtectedRoute = ({ user, children }) => {
        if (user===undefined || !user) {
            return <Navigate to="/" replace />;
        }
        return children;
    };

    return (
        <Suspense fallback="Loading">
            <BrowserRouter>
                <Routes>
                    <Route path="/" exact element={<Login />} />
                    <Route path="/main" exact element={<ProtectedRoute user={User.nome}><Main/></ProtectedRoute>} />  
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
}