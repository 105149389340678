import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { UserContext } from "../../services/usercontext";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Users from "../../components/users";
import LastVersion from "../../components/lastVersion";
import OldVersion from "../../components/oldVersion";
import HomologVersion from "../../components/homologVersion";
import DiversVersion from "../../components/diversVersion";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Main() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { User, setUser } = useContext(UserContext);
  const history = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setUser({});
    history("/");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Portal Saver
          </Typography>
          <div>
            {User.nome}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToApp fontSize="small" />
                </ListItemIcon>
                Sair
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChangeTab} aria-label="Tabs">
            <Tab label="Em Produção" {...a11yProps(0)} />
            <Tab label="Versões anteriores" {...a11yProps(1)} />
            <Tab label="Diversos" {...a11yProps(2)} />
            {User.tipo < 4 && <Tab label="Em Homologação" {...a11yProps(3)} />}
            {User.tipo === 1 && <Tab label="Usuários" {...a11yProps(4)} />}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <LastVersion />{" "}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <OldVersion />{" "}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <DiversVersion />{" "}
        </CustomTabPanel>
        {User.tipo < 4 && (
          <CustomTabPanel value={value} index={3}>
            <HomologVersion />{" "}
          </CustomTabPanel>
        )}
        {User.tipo === 1 && (
          <CustomTabPanel value={value} index={4}>
            <Users />{" "}
          </CustomTabPanel>
        )}
      </Box>
    </Box>
  );
}
