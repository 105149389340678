import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../services/usercontext";
import csuapi from "../services/csuapi";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "90%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
export default function DiversVersion() {
  const { User } = useContext(UserContext);
  const [gridItems, setGridItems] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [state, setState] = useState({ selectedFile: null });
  const [descricao, setDescricao] = useState("");
  const [progress, setProgress] = React.useState(0);
  const history = useNavigate();

  const linkListaDiversos = "/diversos";
  const linkUploadDiversos = "/diversos/upload";
  const linkProgramas = "/prod/programas";
  const config = {
    headers: {
      token: User.token,
      typeID: -1,
    },
  };
  const configUpload = {
    headers: {
      token: User.token,
    },
    onUploadProgress: (progressEvent) => {
      setProgress(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },
  };
  const columns = [
    {
      field: "Div_Id",
      headerName: "Código",
      width: 60,
      editable: false,
      description: "Código do documento.",
      sortable: false,
    },
    {
      field: "Div_Desc",
      headerName: "Descrição",
      width: 500,
      editable: false,
      description: "Descrição do documento.",
    },
    ,
    {
      field: "Div_Path",
      headerName: "Download",
      description: "Link para download do programa.",
      sortable: false,
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Link to={params.value} target="_blank" rel="noopener noreferrer">
          <Tooltip title={params.value} arrow placement="right">
            <DownloadIcon />
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "Div_DataHora",
      headerName: "Data/Hora",
      // type: 'number',
      width: 250,
      editable: false,
      description: "Data/Hora homologação.",
    },
    {
      field: "Div_Usuario",
      headerName: "Usuario",
      // type: 'number',
      width: 300,
      editable: false,
      description: "Usuário qeu fez upload.",
    },
  ];
  function getItemsGrid() {
    csuapi
      .get(linkListaDiversos, config)
      .then(function (response) {
        if (response.data == null) {
          setGridItems({});
        } else {
          setGridItems(response.data);
        }
      })
      .catch(function (error) {
        setGridItems({});
        if (error.response.status === 401) {
          history("/");
        }
      });
  }
  const onFileUpload = () => {
    setFileUpload(true);
  };
  const handleClose = () => {
    setFileUpload(false);
  };
  const onFileChange = (event) => {
    setState({ selectedFile: event.target.files[0] });
  };

  const onFileUploadSend = () => {
    const formData = new FormData();
    formData.append("descricao", descricao);
    formData.append(
      "fileDocuments",
      state.selectedFile,
      state.selectedFile.name
    );
    csuapi
      .post(linkUploadDiversos, formData, configUpload)
      .then(function (response) {
        if (response.data == null) {
        } else {
          getItemsGrid();
          setFileUpload(false);
        }
      })
      .catch(function (error) {
        setFileUpload(false);
      });
  };

  useEffect(() => {
    getItemsGrid();
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Button
          variant="text"
          startIcon={<CloudUploadIcon />}
          onClick={onFileUpload}
        >
          Adicionar novo arquivo
        </Button>
      </Stack>

      <DataGrid
        getRowId={(gridItems) => gridItems.Div_Id}
        rows={gridItems}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: "CodProg", sort: "asc" }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
      />

      <Dialog
        open={fileUpload}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Diversos</DialogTitle>
        <DialogContent>
          <DialogContentText>Forneça descrição</DialogContentText>

          <TextField
            variant={"outlined"}
            label="Descrição"
            fullWidth
            margin="dense"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          ></TextField>
        </DialogContent>
        <DialogContent>
          <input type="file" onChange={onFileChange} />
        </DialogContent>
        <DialogContent>
          <LinearProgressWithLabel variant="determinate" value={progress} />
        </DialogContent>{" "}
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={onFileUploadSend}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
