import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../services/usercontext";
import csuapi from "../services/csuapi";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "90%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function DiversVersion() {
  const { User } = useContext(UserContext);

  const [gridItems, setGridItems] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [fileVersion, setFileVersion] = useState({ selectedFile: null });
  const [docVersion, setDocVersion] = useState({ selectedFile: null });
  const [manualVersion, setManualVersion] = useState({ selectedFile: null });
  const [ListProgram, setListProgram] = useState([
    { CodProg: 0, DescProg: "Todos os Programas" },
  ]);
  const [programID, setProgramID] = useState("");
  const [versionTXT, setVersionTXT] = useState("");
  const [progress, setProgress] = React.useState(0);

  const linkhomologVersion = "/hml";
  const linkUploadFiles = "/hml/upload";
  const linkApproval = "/hml/approval";
  const linkDisapproved = "/hml/disapproved";
  const getProgramas = "/prod/programas";
  const config = {
    headers: {
      token: User.token,
      typeUser: User.tipo,
    },
  };
  const configUpload = {
    headers: {
      token: User.token,
    },
    onUploadProgress: (progressEvent) => {
      setProgress(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },
  };
  const configApproval = {
    headers: {
      token: User.token,
    },
  };
  const configDisapproved = {
    headers: {
      token: User.token,
    },
  };
  const columns = [
    {
      field: "CodProg",
      headerName: "Código",
      width: 70,
      editable: false,
      description: "Código do programa.",
      sortable: false,
    },
    {
      field: "DescProg",
      headerName: "Descrição",
      width: 350,
      editable: false,
      description: "Descrição do programa.",
    },

    {
      field: "VerProg",
      headerName: "Versão",
      // type: 'number',
      width: 150,
      editable: false,
      description: "Versão disponibilizada.",
    },
    {
      field: "LinkVersion",
      headerName: "Download",
      description: "Link para download do programa.",
      sortable: false,
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Link to={params.value} target="_blank" rel="noopener noreferrer">
          <Tooltip title={params.value} arrow placement="right">
            <DownloadIcon />
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "LinkDoc",
      headerName: "Release Notes",
      description: "Link para download do Release Notes.",
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (params) =>
        params.value !== "" && (
          <Link to={params.value} target="_blank" rel="noopener noreferrer">
            <Tooltip title={params.value} arrow placement="right">
              <DownloadIcon />
            </Tooltip>
          </Link>
        ),
      // valueGetter: (params) =>
      // `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "LinkManual",
      headerName: "Manual",
      description: "Link para download do manual.",
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (params) =>
        params.value !== "" && (
          <Link to={params.value} target="_blank" rel="noopener noreferrer">
            <Tooltip title={params.value} arrow placement="right">
              <DownloadIcon />
            </Tooltip>
          </Link>
        ),
      // valueGetter: (params) =>
      // `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "VersionStatus",
      headerName: "Status",
      width: 70,
      editable: false,
      description: "Status da Versão.",
      sortable: false,
    },
    {
      field: "DataHora",
      headerName: "Data/Hora",
      width: 250,
      editable: false,
      description: "Data/Hora da inclusão.",
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Ações",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CheckCircleOutlineOutlinedIcon />}
          label="Aprovar versão"
          onClick={approvalVersion(params.row["VersionID"])}
        />,
        <GridActionsCellItem
          icon={<CancelPresentationOutlinedIcon />}
          label="Reprovar versão"
          onClick={disapprovedVersion(params.row["VersionID"])}
          // showInMenu
        />,
      ],
    },
  ];
  const columnVisibilityModel = React.useMemo(() => {
    return {
      CodProg: true,
      DescProg: true,
      PathInstall: true,
      VerProg: true,
      LinkVersion: true,
      LinkDoc: true,
      VersionID: false,
      VersionStatus: true,
      DataHora: true,
      actions: true,
    };
  }, []);
  function getItemsGrid() {
    csuapi
      .get(linkhomologVersion, config)
      .then(function (response) {
        if (response.data == null) {
          setGridItems({});
        } else {
          setGridItems(response.data);
        }
      })
      .catch(function (error) {
        setGridItems({});
      });
  }
  const onFileUpload = () => {
    setProgramID("");
    setVersionTXT("");
    setFileUpload(true);
  };
  const handleClose = () => {
    setFileUpload(false);
  };

  const onFileChange = (event) => {
    setFileVersion({ selectedFile: event.target.files[0] });
  };

  const onDocChange = (event) => {
    setDocVersion({ selectedFile: event.target.files[0] });
  };

  const onManualChange = (event) => {
    setManualVersion({ selectedFile: event.target.files[0] });
  };

  const onProgramIDChange = (evento) => {
    setProgramID(evento.target.value);
  };

  const onVersionTXTChange = (evento) => {
    setVersionTXT(evento.target.value);
  };

  const onFileUploadSend = () => {
    const formData = new FormData();
    formData.append("programID", programID);
    formData.append("versionNumber", versionTXT);
    formData.append(
      "fileVersion",
      fileVersion.selectedFile,
      fileVersion.selectedFile.name
    );
    if (docVersion.selectedFile != null) {
      formData.append(
        "fileDocuments",
        docVersion.selectedFile,
        docVersion.selectedFile.name
      );
    }
    if (manualVersion.selectedFile != null) {
      formData.append(
        "fileManual",
        manualVersion.selectedFile,
        manualVersion.selectedFile.name
      );
    }

    csuapi
      .post(linkUploadFiles, formData, configUpload)
      .then(function (response) {
        setFileUpload(false);
        getItemsGrid();
      })
      .catch(function (error) {
        setFileUpload(false);
      });
  };

  const approvalVersion = React.useCallback(
    (versionID) => () => {
      const formData = new FormData();
      formData.append("versionID", versionID);

      csuapi
        .post(linkApproval, formData, configApproval)
        .then(function (response) {
          getItemsGrid();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    []
  );
  const disapprovedVersion = React.useCallback(
    (versionID) => () => {
      const formData = new FormData();
      formData.append("versionID", versionID);

      csuapi
        .post(linkDisapproved, formData, configDisapproved)
        .then(function (response) {
          getItemsGrid();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    []
  );
  const getListProgram = () => {
    csuapi
      .get(getProgramas, config)
      .then(function (response) {
        if (response.data == null) {
          setListProgram([{ CodProg: 0, DescProg: "Todos os Programas" }]);
          setProgramID("");
        } else {
          setListProgram(response.data);
          setProgramID("");
        }
      })
      .catch(function (error) {
        setListProgram([{ CodProg: 0, DescProg: "Todos os Programas" }]);
        setProgramID("");
      });
  };

  useEffect(() => {
    getItemsGrid();
    getListProgram();
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Button
          variant="text"
          startIcon={<CloudUploadIcon />}
          onClick={onFileUpload}
        >
          Adicionar novo arquivo
        </Button>
      </Stack>

      <DataGrid
        getRowId={(gridItems) => gridItems.VersionID}
        rows={gridItems}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: "CodProg", sort: "asc" }],
            // sortModel: [{ field: 'DescProg', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        columnVisibilityModel={columnVisibilityModel}
      />

      <Dialog
        open={fileUpload}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Enviar para Homologação
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Informe o dados do programa e versão.
          </DialogContentText>
          <TextField
            select
            label="Selecione um programa"
            fullWidth
            margin="dense"
            value={programID}
            onChange={onProgramIDChange}
          >
            {ListProgram.map((item) => (
              <MenuItem key={item.CodProg} value={item.CodProg}>
                {item.DescProg}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            required
            id="outlined-required"
            label="Versão"
            defaultValue={versionTXT}
            helperText="Informe a versão"
            onChange={onVersionTXTChange}
          />
        </DialogContent>

        <DialogContent>
          <DialogContentText>Anexe o arquivo da Versão.</DialogContentText>
          <input type="file" onChange={onFileChange} />
        </DialogContent>

        <DialogContent>
          <DialogContentText>
            Anexe o arquivo da Release Notes.
          </DialogContentText>
          <input type="file" onChange={onDocChange} />
        </DialogContent>
        <DialogContent>
          <DialogContentText>Anexe o arquivo do Manual.</DialogContentText>
          <input type="file" onChange={onManualChange} />
        </DialogContent>
        <DialogContent>
          <LinearProgressWithLabel variant="determinate" value={progress} />
        </DialogContent>

        <DialogActions>
          <Button startIcon={<CloseTwoToneIcon />} onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CloudUploadIcon />} onClick={onFileUploadSend}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
