import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../services/usercontext";
import csuapi from "../services/csuapi";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
export default function OldVersion() {
  const { User } = useContext(UserContext);
  const [gridItems, setGridItems] = useState([]);

  const [programID, setProgramID] = useState(0);
  const [programas, setProgramas] = useState([
    { CodProg: 0, DescProg: "Todos" },
  ]);

  const link = "/prod/versoes";
  const linkProgramas = "/prod/programas";
  const config = {
    headers: {
      token: User.token,
      tipo: 0,
    },
  };
  const columns = [
    {
      field: "CodProg",
      headerName: "Código",
      width: 70,
      editable: false,
      description: "Código do programa.",
      sortable: false,
    },
    {
      field: "DescProg",
      headerName: "Descrição",
      width: 350,
      editable: false,
      description: "Descrição do programa.",
    },
    {
      field: "VerProg",
      headerName: "Versão",
      // type: 'number',
      width: 150,
      editable: false,
      description: "Versão disponibilizada.",
    },
    {
      field: "LinkVersion",
      headerName: "Download",
      description: "Link para download do programa.",
      sortable: false,
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Link to={params.value} target="_blank" rel="noopener noreferrer">
          <Tooltip title={params.value} arrow placement="right">
            <DownloadIcon />
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "LinkDoc",
      headerName: "Release Notes",
      description: "Link para download do release notes.",
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (params) =>
        params.value !== "" && (
          <Link to={params.value} target="_blank" rel="noopener noreferrer">
            <Tooltip title={params.value} arrow placement="right">
              <DownloadIcon />
            </Tooltip>
          </Link>
        ),
    },
    {
      field: "LinkManual",
      headerName: "Manual",
      description: "Link para download do manual.",
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (params) =>
        params.value !== "" && (
          <Link to={params.value} target="_blank" rel="noopener noreferrer">
            <Tooltip title={params.value} arrow placement="right">
              <DownloadIcon />
            </Tooltip>
          </Link>
        ),
    },
    {
      field: "DataHora",
      headerName: "Data/Hora",
      // type: 'number',
      width: 300,
      editable: false,
      description: "Data/Hora homologação.",
    },
  ];
  function getItemsGrid() {
    config.headers["programID"] = programID;

    csuapi
      .get(link, config)
      .then(function (response) {
        if (response.data == null) {
          setGridItems({});
        } else {
          setGridItems(response.data);
        }
      })
      .catch(function (error) {
        setGridItems({});
      });
  }

  const handleProgramChange = (event) => {
    setProgramID(event.target.value);
  };

  function getProgramas() {
    csuapi
      .get(linkProgramas, config)
      .then(function (response) {
        setProgramas(response.data);
      })
      .catch(function (error) {});
  }

  useEffect(() => {
    getItemsGrid();
  }, [programID]);

  useEffect(() => {
    getProgramas();
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Stack direction="row" spacing={2}>
        <TextField
          select
          variant={"outlined"}
          label="Filtrar Programa"
          sx={{ width: 250 }}
          value={programID}
          onChange={handleProgramChange}
        >
          {programas.map((item) => (
            <MenuItem key={item.CodProg} value={item.CodProg}>
              {item.DescProg}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <DataGrid
        getRowId={(gridItems) => gridItems.VersionID}
        rows={gridItems}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: "CodProg", sort: "asc" }],
            // sortModel: [{ field: 'DescProg', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
    </Box>
  );
}
