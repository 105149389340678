import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../services/usercontext";
import csuapi from "../services/csuapi";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import BlockIcon from "@mui/icons-material/Block";
import LooksOneTwoToneIcon from "@mui/icons-material/LooksOneTwoTone";
import LooksTwoTwoToneIcon from "@mui/icons-material/LooksTwoTwoTone";
import Looks3TwoToneIcon from "@mui/icons-material/Looks3TwoTone";
import Looks4TwoToneIcon from "@mui/icons-material/Looks4TwoTone";

export default function Users() {
  const { User } = useContext(UserContext);
  const [gridItems, setGridItems] = useState([]);

  const linkGetUsers = "/users";
  const linkSetType = "/users/settype";
  const linkSetStatus = "/users/setstatus";
  const config = {
    headers: {
      token: User.token,
      tipo: 0,
    },
  };

  function getUsers() {
    csuapi
      .get(linkGetUsers, config)
      .then(function (response) {
        if (response.data == null) {
          setGridItems({});
        } else {
          setGridItems(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        setGridItems({});
      });
  }
  const settypeconfig = {
    headers: {
      token: User.token,
    },
  };
  const setType = React.useCallback(
    (usuario_id, usuario_tipo) => () => {
      const formData = new FormData();
      formData.append("usuario_id", usuario_id);
      formData.append("usuario_tipo", usuario_tipo);

      csuapi
        .post(linkSetType, formData, settypeconfig)
        .then(function (response) {
          getUsers();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    []
  );

  const setStatus = React.useCallback(
    (usuario_id, usuario_status) => () => {
      const formData = new FormData();
      formData.append("usuario_id", usuario_id);
      if (usuario_status === 1) {
        formData.append("usuario_status", 0);
      } else {
        formData.append("usuario_status", 1);
      }

      csuapi
        .post(linkSetStatus, formData, settypeconfig)
        .then(function (response) {
          getUsers();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    []
  );

  const columns = [
    {
      field: "usuario_ad",
      headerName: "Usuário AD",
      width: 200,
      editable: false,
      description: "Usuário do AD.",
      sortable: false,
    },
    {
      field: "usuario_nome",
      headerName: "Nome Usuário",
      width: 350,
      editable: false,
      description: "Nome Completo do Usuário.",
    },
    {
      field: "usuario_email",
      headerName: "eMail",
      width: 350,
      editable: false,
      description: "e_Mail do Usuário.",
    },
    {
      field: "usuario_tipo",
      headerName: "Nível",
      width: 100,
      editable: false,
      description: "Nível do Usuário.",
    },
    {
      field: "usuario_status",
      headerName: "Status",
      width: 100,
      editable: false,
      description: "Status do Usuário.",
      renderCell: (params) => (params.value === 1 ? "Ativo" : "Bloqueado"),
    },
    {
      field: "actions",
      headerName: "Ações",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BlockIcon />}
          label="Bloqueia/Desbloqueia"
          onClick={setStatus(
            params.row["usuario_id"],
            params.row["usuario_status"]
          )}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<LooksOneTwoToneIcon />}
          label="Desenvolvedor"
          onClick={setType(params.row["usuario_id"], 1)}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<LooksTwoTwoToneIcon />}
          label="Homologação 1"
          onClick={setType(params.row["usuario_id"], 2)}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<Looks3TwoToneIcon />}
          label="Homologação 2"
          onClick={setType(params.row["usuario_id"], 3)}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<Looks4TwoToneIcon />}
          label="Usuário"
          onClick={setType(params.row["usuario_id"], 4)}
        />,
      ],
    },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        getRowId={(gridItems) => gridItems.usuario_id}
        rows={gridItems}
        rowHeight={34}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: "usuario_ad", sort: "asc" }],
            // sortModel: [{ field: 'DescProg', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        // checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>
  );
}
